import { Box, Stack, useTheme } from '@mui/material';
import { CertifiedCropAdvisorSvg, CustomInputLabel, CustomTextInput, Icon, IconButton, SignatureModal } from 'features';
import { Field } from 'formik';
import { useAppSelector } from 'src/hooks/hooks';
import { UserProfile } from 'src/services/rtkQuery/types/user.types';
import { selectUser } from 'src/store/user/userSlice';
import { useState } from 'react';

interface MyProfileProps {
    userData: UserProfile | undefined;
    onSubmitSignature: (file: File) => void;
    signatureUrl?: string;
};

export const MyProfile = ({ userData, onSubmitSignature, signatureUrl }: MyProfileProps) => {
    const [signatureModal, setSignatureModal] = useState(false);

    const theme = useTheme();
    const user = useAppSelector(selectUser);

    const handleEditSignature = () => {
        setSignatureModal(!signatureModal);
    };

    return (
        <Stack gap={1}>
            <Stack direction='row' justifyContent='space-between'>
                <CustomTextInput
                    label='Name'
                    disabled={true}
                    value={userData?.name}
                    sx={{ width: '265px' }}
                />
                <CustomTextInput
                    label='Title'
                    disabled={true}
                    value={userData?.title}
                    sx={{ width: '265px' }}
                />
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <CustomTextInput
                    label='Cellphone'
                    disabled={true}
                    value={userData?.cellphone}
                    sx={{ width: '265px' }}
                />
                <CustomTextInput
                    label='Email'
                    disabled={true}
                    value={user.firebaseUser?.email}
                    sx={{ width: '265px' }}
                />
            </Stack>
            <Stack direction='row' justifyContent='space-between'>
                <Field
                    name='cropLifeSaId'
                    as={CustomTextInput}
                    label='CropLife SA (Avcasa) ID'
                    placeholder='Enter CropLife SA ID'
                    sx={{ width: '265px' }}
                />
                <Field
                    name='basosId'
                    as={CustomTextInput}
                    label='Facts/Basos ID'
                    placeholder='Enter Facts/Basos ID'
                    sx={{ width: '265px' }}
                />
            </Stack>
            <Stack direction='row' gap={9}>
                {
                    signatureUrl !== ''
                        ?
                        <Stack>
                            <CustomInputLabel title='Signature' htmlFor='' />
                            <Box
                                onClick={handleEditSignature}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    border: `1px solid ${theme.colors.alpha.grey[2]}`,
                                    borderRadius: '16px',
                                    height: '100px',
                                    width: '265px',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        border: `1px solid ${theme.colors.alpha.grey[5]}`
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '230px',
                                    }}
                                >
                                    <img src={signatureUrl} alt='User Signature' height='90px' />
                                </Box>
                                <Icon icon='UilPen' sx={{ mr: 1.5 }} />
                            </Box>
                        </Stack>

                        : <CustomTextInput
                            label='Signature'
                            placeholder='Add signature'
                            onClick={handleEditSignature}
                            InputProps={{
                                endAdornment: <IconButton icon='UilPen' />,
                                notched: false
                            }}
                            sx={{ width: '265px' }}
                        />
                }
                {
                    userData?.isCertified && (
                        <Box pt={2}>
                            <img src={CertifiedCropAdvisorSvg} alt='InteliGro Certified Crop Advisor Logo' />
                        </Box>
                    )
                }
            </Stack>
            <SignatureModal
                open={signatureModal}
                toggleOpen={handleEditSignature}
                onSubmit={onSubmitSignature}
            />
        </Stack>
    );
};