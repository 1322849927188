import { GridCellParams, GridRowParams } from '@mui/x-data-grid';
import {
	IHeader,
	createHeaders,
	BasicAction,
	ReadableLibraryDocumentType,
	LibraryDocumentType,
	ICustomActions,
	getReadableEnum,
	LibraryFolder,
	formatDateAndTime,
} from 'features';

const hasViewPermissions = (
	params: GridRowParams,
	platform: 'TMS' | 'CA' | '4U'
) => {
	switch (platform) {
		case 'TMS':
			return true;
		case 'CA':
			return params.row.caCanView || params.row.caViewDownload;
		case '4U':
			return params.row._4UCanView;
		default:
			return false;
	}
};

const hasDownloadPermissions = (
	params: GridRowParams,
	platform: 'TMS' | 'CA' | '4U'
) => {
	switch (platform) {
		case 'TMS':
			return true;
		case 'CA':
			return params.row.caViewDownload;
		case '4U':
			return false;
		default:
			return false;
	}
};

const actions = (
	platform: 'TMS' | 'CA' | '4U',
	onEditClick?: (params: GridRowParams) => void,
	onDeleteClick?: (params: GridRowParams) => void,
	onDownloadClick?: (params: GridRowParams) => void,
	onPermissionEdit?: (params: GridRowParams) => void,
	onViewClick?: (params: GridRowParams) => void
) => {
	const actions: ICustomActions[] = [];

	if (onEditClick)
		actions.push({
			getAction: () =>
				BasicAction({
					onClick: onEditClick,
					title: 'Edit',
					icon: 'UilEditAlt',
				}),
		});

	if (onDeleteClick)
		actions.push({
			getAction: () =>
				BasicAction({
					onClick: onDeleteClick,
					title: 'Delete',
					icon: 'UilTrashAlt',
				}),
		});

	if (onDownloadClick)
		actions.push({
			getAction: (params) => {
				return (
					hasDownloadPermissions(params, platform) &&
					BasicAction({
						onClick: onDownloadClick,
						title: 'Download',
						icon: 'UilDownloadAlt',
					})
				);
			},
		});

	if (onPermissionEdit)
		actions.push({
			getAction: () =>
				BasicAction({
					onClick: onPermissionEdit,
					title: 'Permissions',
					icon: 'UilLock',
				}),
		});

	if (onViewClick)
		actions.push({
			getAction: (params) => {
				return (
					hasViewPermissions(params, platform) &&
					BasicAction({
						onClick: onViewClick,
						title: 'View',
						icon: 'UilEye',
					})
				);
			},
		});

	return actions;
};

const headers = (showFolder: boolean): Array<IHeader> => {
	const headers: Array<IHeader> = [
		{
			field: 'fileName',
			headerName: 'Name',
			icon: 'UilListUl',
		},
		{
			field: 'version',
			headerName: 'Version',
			icon: 'UilAnalytics',
			maxWidth: 200,
		},
		{
			field: 'documentType',
			headerName: 'Document Type',
			icon: 'UilDocumentInfo',
			renderCell: (params: GridCellParams) => {
				console.log(params);
				return ReadableLibraryDocumentType[
					LibraryDocumentType[
						params.row
							.documentType as string as keyof typeof LibraryDocumentType
					]
				];
			},
		},
	];

	if (showFolder)
		headers.push({
			field: 'folder',
			headerName: 'Folder',
			icon: 'UilFolder',
			valueGetter: (params: GridCellParams) =>
				getReadableEnum(
					LibraryFolder,
					LibraryFolder[params.value as LibraryFolder]
				),
		});

	headers.push(
		{
			field: 'createdDate',
			headerName: 'Uploaded Date',
			icon: 'UilListUl',
			valueGetter: (params: GridCellParams) => params.row?.createdDate ? formatDateAndTime(new Date(params.row?.createdDate)) : ''
		},
	)

	return headers;
};

export const LibraryDataGridHeaders = (
	platform: 'TMS' | 'CA' | '4U',
	showFolder: boolean,
	onEditClick?: (params: GridRowParams) => void,
	onDeleteClick?: (params: GridRowParams) => void,
	onDownloadClick?: (params: GridRowParams) => void,
	onPermissionEdit?: (params: GridRowParams) => void,
	onViewClick?: (params: GridRowParams) => void
) =>
	createHeaders(
		headers(showFolder),
		actions(
			platform,
			onEditClick,
			onDeleteClick,
			onDownloadClick,
			onPermissionEdit,
			onViewClick
		)
	);
